import React from 'react';
import { graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Badges } from '../components/Badges/';
import { Hero } from '../components/Hero/';
import { Container, Title, SubTitle } from '../styles/share';

const NotFoundPage = ({ data: { Postbote } }) => (
  <PageLayout>
    <SEO title="Du wurdest zu DropFriends eingeladen" />
    <Hero
      image={Postbote}
      title="Verpasse nie wieder den Paketboten!"
      subTitle="Verdiene Geld mit dem Annehmen von Sendungen."
      fullscreen>
      <Badges />
    </Hero>

    <Container>
      <Title>Lade jetzt die App DropFriends auf Dein Smartphone und leg direkt los!</Title>

      <SubTitle>Erhältlich im App Store und im Google Play Store.</SubTitle>
      <Badges className="mt-4" />
    </Container>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default NotFoundPage;
